export const permissions = [
    {
        id: 1,
        type: 1,
        name: "Projects",
        description: "Manage projects",
        actions: [
            { id: 101, name: "View", description: "View projects", value: "read_project" },
            { id: 102, name: "Create / Edit", description: "Create and edit projects", value: "write_project" },
            { id: 103, name: "Manage users", description: "Manage users" },
            { id: 104, name: "Close", description: "Close project so it no longer can be edited" },
            { id: 105, name: "Archive / Unarchive", description: "Move the project to the archive and back. The project will not be visible in the archive" },
            { id: 106, name: "Delete", description: "Delete projects", value: "delete_project" }
        ]
    },
    {
        id: 2,
        type: 1,
        name: "Milestones",
        description: "Manage milestones",
        actions: [
            { id: 201, name: "View", description: "View milestones", value: "read_milestone" },
            { id: 202, name: "Create / Edit", description: "Create and edit milestones", value: "write_milestone" },
            { id: 203, name: "Close", description: "Close milestones" },
            { id: 204, name: "Block", description: "Block milestone" },
            { id: 205, name: "Delete", description: "Delete projects", value: "delete_milestone" }
        ]
    },
    {
        id: 3,
        type: 1,
        name: "Dashboard",
        description: "Manage Dashboard",
        actions: [
            { id: 301, name: "View", description: "View Dashboard", value: "read_dashboard" },
            { id: 302, name: "Customize", description: "Add or delete charts" },
            { id: 303, name: "Edit", description: "Edit Dashboard view and charts settings", value: "write_dashboard" },
            { id: 304, name: "Delete", description: "Delete charts", value: "delete_dashboard" }
        ]
    },
    {
        id: 4,
        type: 2,
        name: "Test cases",
        description: "Manage test cases",
        actions: [
            { id: 401, name: "View", description: "View test cases and test folders", value: "read_case" },
            { id: 402, name: "Create / Edit", description: "Create and edit test cases", value: "write_case" },
            { id: 403, name: "Change position", description: "Drag and drop test cases" },
            { id: 404, name: "Add comments", description: "Add and edit comments" },
            { id: 405, name: "Import", description: "Import test cases" },
            { id: 406, name: "Export", description: "Export test cases" },
            { id: 407, name: "Remove", description: "Remove test cases from project", value: "delete_case" },
            {
                id: 408,
                name: "Test folders",
                description: "Manage the organization of test cases within test folders",
                actions: [
                    { id: 40801, name: "Create / Edit", description: "Create and edit test folders", value: "write_folder" },
                    { id: 40802, name: "Change position", description: "Drag and drop test folders" },
                    { id: 40803, name: "Delete", description: "Delete test folders", value: "delete_folder" }
                ]
            }
        ]
    },
    {
        id: 5,
        type: 1,
        name: "Test runs",
        description: "Manage test runs",
        actions: [
            { id: 501, name: "View", description: "View test runs" },
            { id: 502, name: "Create / Edit", description: "Create and edit test runs" },
            { id: 503, name: "Start", description: "Start test run and add statuses" },
            { id: 504, name: "Delete", description: "Delete test runs" }
        ]
    },
    {
        id: 6,
        type: 1,
        name: "Test plans",
        description: "Manage test plans",
        actions: [
            { id: 601, name: "View", description: "View test plans" },
            { id: 602, name: "Create / Edit", description: "Create and edit test plans" },
            { id: 603, name: "Start", description: "Start test plans and add statuses" },
            { id: 604, name: "Delete", description: "Delete test plans" }
        ]
    },
    {
        id: 7,
        type: 1,
        name: "Defects",
        description: "Manage defects",
        actions: [
            { id: 701, name: "Link / Edit", description: "Create and edit defects" },
            { id: 702, name: "Unlink", description: "Delete defects" }
        ]
    },
    {
        id: 8,
        type: 1,
        name: "Analytics",
        description: "Manage Analytics",
        actions: [
            { id: 801, name: "View", description: "View Analytics" },
            { id: 802, name: "Customize", description: "Add new chart" },
            { id: 803, name: "Edit", description: "Edit Analytics view and charts settings" },
            { id: 804, name: "Delete", description: "Delete charts" }
        ]
    },
    {
        id: 9,
        type: 1,
        name: "Reports",
        description: "Manage reports",
        actions: [
            { id: 901, name: "View", description: "Allow only to view report", value: "read_report" },
            { id: 902, name: "Create / Edit public report", description: "Create / Edit public report", value: "write_report" },
            { id: 903, name: "Export", description: "Export reports" },
            { id: 904, name: "Delete", description: "Delete reports", value: "delete_report" }
        ]
    },
    {
        id: 10,
        type: 1,
        name: "Users",
        description: "Manage users",
        actions: [
            { id: 1001, name: "Add", description: "Add new user" },
            { id: 1002, name: "Request invite", description: "Invite new user, the user will be added only after the consent of the administrator" },
            { id: 1003, name: "Delete", description: "Delete users" }
        ]
    },
    {
        id: 11,
        type: 2,
        name: "Roles",
        description: "Manage roles",
        actions: [
            {
                id: 1101,
                name: "Add / Edit",
                description: "Add and edit role",
                value: "write_role",
                actions: [
                    { id: 110101, name: "Account admin", description: "Add users with account admin role" },
                    { id: 110102, name: "Project admin", description: "Add users with project admin role" },
                    { id: 110103, name: "QA Lead", description: "Add users with QA lead role" },
                    { id: 110104, name: "Tester", description: "Add users with tester role" },
                    { id: 110105, name: "Client", description: "Add users with client role" },
                    { id: 110106, name: "Billing role", description: "Add users with billing role" },
                ]
            },
            { id: 1102, name: "Set roles by project", description: "Set roles by project" },
            { id: 1103, name: "Assign roles", description: "Assign roles" },
            { id: 1104, name: "Delete", description: "Delete role", value: "delete_role" }
        ]
    },
    {
        id: 12,
        type: 1,
        name: "Tags",
        description: "Manage tags",
        actions: [
            { id: 1201, name: "Add / Edit", description: "Add and edit tag", value: "write_tag" },
            { id: 1202, name: "Archive / Unarchive", description: "Move the tag to the archive and back. The tag will not be visible in the archive" },
            { id: 1203, name: "Delete", description: "Delete tags", value: "delete_tag" }
        ]
    },
    {
        id: 13,
        type: 1,
        name: "Custom fields",
        description: "Manage custom fields",
        actions: [
            { id: 1301, name: "Add / Edit", description: "Add and edit custom field", value: "write_custom_field" },
            { id: 1302, name: "Use", description: "Use the custom fields created by others in their test design" },
            { id: 1303, name: "Delete", description: "Delete custom field", value: "delete_custom_field" }
        ]
    },
    {
        id: 14,
        type: 1,
        name: "Shared steps",
        description: "Manage shared steps",
        actions: [
            { id: 1401, name: "Create / Edit", description: "Create and edit shared steps", value: "write_step" },
            { id: 1402, name: "Archive / Unarchive", description: "Move the shared step to the archive and back. The shared step will not be visible in the archive" },
            { id: 1403, name: "Use", description: "Use the shared steps created by others in test cases" },
            { id: 1404, name: "Delete", description: "Delete shared steps", value: "delete_step" }
        ]
    },
    {
        id: 15,
        type: 3,
        name: "Billing role",
        description: "Manage billing information",
        actions: [
            { id: 1501, name: "Full access", description: "Full access to subscription details, payment history and to modify billing settings and payment methods", value: "full_billing" },
            { id: 1502, name: "Limited access", description: "No access to modify billing settings and payment methods", value: "limited_billing" },
            { id: 1503, name: "No access", description: "No access", value: "no_billing" }
        ]
    },
    {
        id: 16,
        type: 1,
        name: "Integration",
        description: "Manage integration",
        actions: [
            { id: 1601, name: "Add / Edit", description: "Add and edit integration", value: "write_integration" },
            { id: 1602, name: "Delete", description: "Delete integration", value: "delete_integration" }
        ]
    },
];
