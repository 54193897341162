<template>
    <v-container fluid>
        <Header @back-to-roles="backToRoles" />

        <v-card class="py-6 px-6 mt-3" rounded="lg" elevation="0" width="100%">
            <v-form v-model="valid" ref="form">
                <div class="d-flex justify-space-between align-center flex-row mb-5">
                    <div class="w-full mr-3">
                        <p class="text-body-2">{{ $t('name') }}</p>
                        <v-text-field full-width :placeholder="$t('name')" v-model="roleName"
                            class="custom_input round-8 pl-3" dense height="38px" :rules="nameRules" required />
                    </div>
                    <div class="w-full ml-3">
                        <p class="text-body-2">{{ $t('description') }}</p>
                        <v-text-field full-width :placeholder="$t('description')" v-model="roleDescription"
                            class="custom_input round-8 pl-3" dense height="38px" />
                    </div>
                </div>
                <div>
                    <h4 class="medium mb-5">Permission</h4>
                    <div v-for="(item, index) in items" :key="index">
                        <template v-if="item.type == 1">
                            <CheckboxPanel :items="item.actions" :parent-label="item.name"
                                :description="item.description"
                                @update-items="(updatedItems) => checkboxUpdate(updatedItems, index)" />
                        </template>
                        <template v-else-if="item.type == 2">
                            <NestedCheckboxPanel :items="item.actions" :parent-label="item.name"
                                :description="item.description"
                                @update-items="(updatedItems) => nestedCheckboxUpdate(updatedItems, index)" />
                        </template>
                        <template v-else>

                            <v-expansion-panels mandatory flat>
                                <v-expansion-panel>
                                    <v-expansion-panel-header class="pa-0">
                                        <div class="d-flex justify-start align-center">
                                            <div class="cw-custom">
                                                <p class="text-body-2 mb-0">{{ item.name }}</p>
                                            </div>
                                            <p class="text-body-2 mb-0 grey--text ml-custom">{{ item.description }}</p>
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-radio-group v-model="billing" mandatory>
                                            <div class="d-flex justify-start align-center" style="height: 66px;"
                                                v-for="(_item) in item.actions" :key="_item.id">
                                                <v-radio :label="_item.name" :value="_item.value" class="mb-1 cw-custom"
                                                    @change="onRadioChange(_item.value, index)"></v-radio>
                                                <p class="text-body-2 mb-0 grey--text ml-custom">{{ _item.description }}
                                                </p>
                                            </div>
                                        </v-radio-group>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </template>
                    </div>
                </div>
                <div class="d-flex justify-end">
                    <v-btn :disabled="!valid" color="primary" class="mr-4" @click="validate" :loading="loading">
                        Create role
                    </v-btn>
                </div>
            </v-form>
        </v-card>
    </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import makeRoleService from '@/services/api/role';
import Header from '@/components/Admin/Role/HeaderCreate';
import { permissions } from '@/constants/rolePermissions';
import CheckboxPanel from '@/components/Project/CheckboxPanel.vue';
import NestedCheckboxPanel from '@/components/Project/NestedCheckboxPanel.vue';

const { mapState } = createNamespacedHelpers('user');

export default {
    name: 'CreateRoleView',

    components: {
        Header,
        CheckboxPanel,
        NestedCheckboxPanel
    },

    data() {
        return {
            valid: false,
            roleName: '',
            roleDescription: '',
            items: [],
            billing: null,
            selected: [],
            loading: false,
            nameRules: [
                v => !!v || 'Name is required'
            ],
        };
    },
    methods: {
        backToRoles() {
            this.$router.push({ name: 'Roles' });
        },
        checkboxUpdate(updatedItems, index) {
            const existingItemIndex = this.selected.findIndex(item => item.id === index);
            if (existingItemIndex !== -1) {
                this.selected[existingItemIndex].items = updatedItems;
            } else {
                this.selected.push({
                    id: index,
                    items: updatedItems
                });
            }
        },
        nestedCheckboxUpdate(updatedItems, index) {
            const existingItemIndex = this.selected.findIndex(item => item.id === index);
            if (existingItemIndex !== -1) {
                this.selected[existingItemIndex].items = updatedItems;
            } else {
                this.selected.push({
                    id: index,
                    items: updatedItems
                });
            }
        },
        onRadioChange(value, index) {
            const existingItemIndex = this.selected.findIndex(item => item.id === index);
            if (existingItemIndex !== -1) {
                this.selected[existingItemIndex].items = value;
            } else {
                this.selected.push({
                    id: index,
                    items: value
                });
            }
        },
        validate() {
            if (this.$refs.form.validate()) {
                const roleMembersService = makeRoleService(this.$api);
                this.loading = true;
                roleMembersService.createRole(this.currentAccount.handle, {
                    name: this.roleName,
                    description: this.roleDescription,
                    permissions: this.flattenedSelectedItems
                }).then(() => {
                    this.$router.push({ name: 'Roles' });
                }).finally(() => {
                    this.loading = false;
                });
            }
        }
    },
    computed: {
        ...mapState(['currentAccount']),
        flattenedSelectedItems() {
            const data = this.selected.map(item => item.items);
            return data.flat(1);
        }
    },
    mounted() {
        this.items = permissions;
        this.selected.push({
            id: 14,
            items: "full_billing"
        });
    }
}
</script>

<style scoped>
.custom_input {
    background-color: #f9f9fb;
    height: 38px;
}

.ml-custom {
    margin-left: 20rem;
}

.cw-custom {
    width: 100%;
    max-width: 160px;
}
</style>
